import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
 
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/User/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BASE_URL}/api/User`,   userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, Data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/Auth/change-password`, Data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPhoto(ctx,  {id,base64}) {
      var FormData = require('form-data');
      var data = new FormData();
      data.append('', base64);
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BASE_URL}/api/User/update-avatar/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePic(ctx,{id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/User/remove-avatar/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
