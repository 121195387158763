<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general :user-data="userData" :gat-data="getData" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import accountStoreModule from "./accountStoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    ToastificationContent,
  },
  data() {
    return {
      options: {},
    };
  },

  methods: {
   getData (){
      store
        .dispatch("app-account/fetchUser", { id: optionsLocal.userId })
        .then((response) => {
          store.commit(
            "user/UPDATE_USER",
            JSON.stringify({
              fullName: response.data.data.fullName,
              email: response.data.data.email,
              role: response.data.data.userRoles[0].roleName,
              avatar: response.data.data.avatarFileUrl,
              username: response.data.data.fullName,
              userId: response.data.data.id,
              extras: {
                eCommerceCartItemsCount: 5,
              },
              ability:
                response.data.data.userRoles[0].roleName === "Administrator"
                  ? admin
                  : client,
            })
          );
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(newFile.response.data.errors)) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    },

  },
  setup() {
    const userData = ref(null);
    const optionsLocal = JSON.parse(localStorage.getItem("userData"));

    const USER_APP_STORE_MODULE_NAME = "app-account";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, accountStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const client = [
      {
        action: "read",
        subject: "ACL",
      },
      {
        action: "read",
        subject: "Auth",
      },
    ];
    const admin = [
      {
        action: "manage",
        subject: "all",
      },
    ];
    store.dispatch("user/fetchUser",{id:optionsLocal.userId});

    store
      .dispatch("app-account/fetchUser", { id: optionsLocal.userId })
      .then((response) => {
        (userData.value = response.data.data)
          
      })
      .catch((error) => {
        for (const [key, value] of Object.entries(newFile.response.data.errors)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:value,
          },
        });
        }
      });
  

    return {
      userData,
    };
  },
};
</script>
